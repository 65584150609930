<template>
    <div class="door_record_content">
        <div class="door_record_tabler">
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                <!--                <h5 style="margin-left: 20px;">类型</h5>-->
                <el-select style="margin-left: 10px;" v-model="recordValue" placeholder="请选择"
                           @change="selecRecordChange">
                    <el-option v-for="item in recordType" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
            </div>

            <div v-if="deviceArray.length>0"
                 style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                <!--                <h5 style="margin-left: 20px;">设备</h5>-->
                <el-select style="margin-left: 10px;" v-model="deviceValue" placeholder="选择设备"
                           @change="selectDeviceChange">
                    <el-option v-for="item in deviceArray" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
            </div>

            <div style="margin-left: 10px;">
                <el-date-picker v-model="datePickerValue" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                                @change="datePickerChange">
                </el-date-picker>
            </div>
            <div style="width: 150px;margin-left: 10px">
                <el-input v-model="likeModel" placeholder="请输姓名模糊搜索"
                          @input="likeInput"></el-input>
            </div>
            <div style="width: 180px;margin-left: 20px">
                <el-input v-model="likeIdCardModel" placeholder="请输身份证模糊搜索"
                          @input="likeIdCardInput"></el-input>
            </div>

            <el-button size="mini" type="primary" style="margin-left: 10px;" @click="exportRecordInfo">导出记录信息
            </el-button>
            <el-button size="mini" type="primary" style="margin-left: 10px;" @click="exportRecordPhoto">导出记录图片
            </el-button>
            <el-button size="mini" type="primary" style="margin-left: 10px;" @click="refreshRecord">刷新记录</el-button>
            <el-button size="mini" v-if="selectRecordArray.length>0" type="danger" style="margin-left: 20px;"
                       @click="deleteSelectRecord">删除选中记录
            </el-button>
        </div>
        <div class="door_record_data_content">
            <el-table ref="filterTable" :data="recordData" height="100%" style="width: 100% " v-loading="loading"
                      @selection-change="handleSelectRecord">
                <el-table-column type="selection" align="center" prop="name" label="姓名" width="50">
                </el-table-column>
                <el-table-column align="left" prop="index" sortable label="序号" width="100">
                </el-table-column>
                <el-table-column align="center" prop="fileUrl" label="抓拍照片" width="100">
                    <template scope="scope">
                        <el-image style="width: 80px;height: 80px;" :src="scope.row.fileUrl"
                                  @click="showImage(scope.row.fileUrl)"/>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="personFileUrl" label="底图照片" width="100">
                    <template scope="scope">
                        <el-image style="width: 80px;height: 80px;" :src="scope.row.personFileUrl"
                                  @click="showImage(scope.row.personFileUrl)">
                            <div slot="error" class="image-slot"
                                 style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;height: 100%">
                                <img style="width: 40px;height: 40px;" src="../../../assets/error.png"/>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="name" label="姓名" width="150">
                </el-table-column>
                <el-table-column align="center" prop="personType" label="人员类型" width="120">
                    <template slot-scope="scope">
                        <el-tag type="success" disable-transitions>
                            {{getPersonType(scope.row.personType)}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="verificationType" label="验证类型" width="120">
                    <template slot-scope="scope">
                        <el-tag type="success" disable-transitions>
                            {{getVerificationtype(scope.row.verificationType)}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="temperature" label="体温" width="150">
                </el-table-column>
                <el-table-column align="center" prop="type" label="结果" width="200">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.success? 'success' : 'danger'" disable-transitions>
                            {{scope.row.success?'成功':'失败'}}
                        </el-tag>
                    </template>
                </el-table-column>


                <el-table-column align="center" prop="" label="其它" width="100">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>身份证: {{ scope.row.idCard }}</p>
                            <p>IC卡: {{ scope.row.icCard }}</p>
                            <p>分数: {{ scope.row.score }}</p>
                            <p>设备名称: {{ scope.row.accessDevice.name }}</p>
                            <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">查看</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="date" sortable label="日期" width="200">
                </el-table-column>
                <el-table-column align="center" label="操作" width="auto">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination style="margin-top: 20px;margin-bottom: 20px;" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="page"
                           :page-sizes="[10, 100, 500, 1000, 2000,3000]"
                           :page-size="length" layout="total, sizes, prev, pager, next, jumper" :total="recordCount">
            </el-pagination>
        </div>
        <el-dialog title="查看照片" :visible.sync="showImageDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">
                <el-image :src="dialogPersonFileUrl"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showImageDialogVisible = false">确 定</el-button>
			</span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                recordValue: '全部',
                recordType: [{
                    'name': '全部'
                }, {
                    'name': '访客'
                }, {
                    'name': '成功'
                }, {
                    'name': '失败'
                }, {
                    'name': '黑名单'
                }],
                deviceValue: '全部',
                deviceArray: [{name: '全部'}],
                device: {},
                recordData: [],
                loading: false,
                recordCount: 0,
                page: 1,
                length: 10,
                showImageDialogVisible: false,
                selectRecordArray: [],
                dialogPersonFileUrl: '',
                datePickerValue: [localStorage.getItem('recordStartDate'), localStorage.getItem('recordStopDate')],
                startTime: 0,
                endTime: 0,
                likeModel: null,
                likeIdCardModel: null,
            };
        },
        mounted() {
            this.refreshRecord()
        },
        methods: {
            datePickerChange(datePickerValue) {
                localStorage.setItem('recordStartDate', datePickerValue[0])
                localStorage.setItem('recordStopDate', datePickerValue[1])
                this.refreshRecord()
            },
            likeInput(value) {
                this.refreshRecord()
            },
            likeIdCardInput(value) {
                this.refreshRecord()
            },
            handleSizeChange(length) {
                this.length = length
                this.getRecordList()
            },
            handleCurrentChange(page) {
                this.page = page
                this.getRecordList()
            },
            handleClose(done) {
                this.dialogPersonFileUrl = ''
                done()
            },
            handleSelectRecord(selectRecordArray) {
                this.selectRecordArray = selectRecordArray
            },
            selecRecordChange(value) {
                this.recordValue = value
                localStorage.setItem('recordType', this.recordValue)
                this.getRecordList()
            },
            selectDeviceChange(value) {
                this.deviceValue = value
                if (value === '全部') {
                    this.device = {}
                    this.getRecordList()
                } else {
                    let exist = false
                    for (let i = 0; i < this.deviceArray.length; i++) {
                        let device = this.deviceArray[i]
                        if (value === device.name) {
                            this.device = device
                            exist = true
                            break
                        }
                    }
                    if (!exist) {
                        this.device = {}
                    }
                }
                this.getRecordList()
            },
            getAllDeviceArray() {
                setTimeout(() => {
                    axios.get('/accessDevice/findAll')
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.deviceArray = res.data.data
                                this.deviceArray.push({name: '全部'})
                            } else {
                                this.deviceArray = [{name: '全部'}]
                            }
                        })
                        .catch((error) => {
                            this.deviceArray = [{name: '全部'}]
                        })
                })
            },

            getRecordList() {
                var startTime = new Date(this.datePickerValue[0]).getTime()
                var endTime = new Date(this.datePickerValue[1]).getTime()
                this.recordData = []
                this.loading = true
                var parameter = {}
                var likeParameter = {
                    name: this.likeModel,
                    idCard: this.likeIdCardModel
                }
                if (this.recordValue === '成功') {
                    if (this.device === {}) {
                        parameter = {
                            success: 1
                        }
                    } else {
                        parameter = {
                            success: 1,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '失败') {
                    if (this.device === {}) {
                        parameter = {
                            success: 0,
                        }
                    } else {
                        parameter = {
                            success: 0,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '访客') {
                    if (this.device === {}) {
                        parameter = {
                            personType: 1,
                        }
                    } else {
                        parameter = {
                            personType: 1,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '黑名单') {
                    if (this.device === {}) {
                        parameter = {
                            personType: 3,
                        }
                    } else {
                        parameter = {
                            personType: 3,
                            accessDevice: this.device.id
                        }
                    }

                } else {
                    parameter = {
                        accessDevice: this.device.id
                    }
                }
                setTimeout(() => {
                    axios.post('/accessRecord/pagingQuery', {
                        page: this.page,
                        length: this.length,
                        startTime: startTime,
                        endTime: endTime,
                        parameter: parameter,
                        likeParameter: likeParameter
                    }).then((res) => {
                        this.loading = false
                        if (res.data.code === 1) {
                            this.recordData = res.data.data.content
                            this.recordCount = res.data.data.totalElements
                        } else {
                            this.recordData = []
                            this.recordCount = 0
                        }
                    }).catch((error) => {
                        this.loading = false
                        this.recordData = []
                        this.recordCount = 0
                    })
                })
            },
            errorHandler() {
                return true
            },
            handleDelete(index) {
                var device = this.recordData[index]
                this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    setTimeout((res) => {
                        axios.get('/accessRecord/delete?id=' + device.id)
                            .then((res) => {
                                if (res.data.code === 1) {
                                    this.$message({
                                        type: 'success',
                                        message: res.data.msg
                                    });
                                    this.getRecordList({})
                                } else {
                                    this.$message({
                                        type: 'warning',
                                        message: res.data.msg
                                    });
                                }
                            })
                            .catch((error) => {
                                this.$message({
                                    type: 'warning',
                                    message: '删除失败!'
                                });
                                this.getRecordList({})
                            })
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            getVerificationtype(type) {
                if (type === 0) {
                    return '1:N'
                } else if (type === 1) {
                    return '1:1'
                } else if (type === 2) {
                    return '1:N+T'
                } else if (type === 3) {
                    return '1:1+T'
                } else if (type === 4) {
                    return '仅测温'
                } else if (type === 5) {
                    return '单刷卡'
                } else if (type === 6) {
                    return '单刷卡测温'
                } else if (type === 7) {
                    return '临时访客二维码'
                } else if (type === 8) {
                    return '密码开门'
                } else if (type === 9) {
                    return '远程开门'
                } else if (type === 10) {
                    return '健康码'
                } else {
                    return '1:N'
                }
            },
            showImage(fileUrl) {
                this.dialogPersonFileUrl = fileUrl
                this.showImageDialogVisible = true
            },
            refreshRecord() {
                var value = localStorage.getItem('recordType')
                if (value) {
                    this.recordValue = value
                } else {
                    localStorage.setItem('recordType', this.recordValue)
                }
                this.getRecordList()
                this.getAllDeviceArray()
            },
            deleteSelectRecord() {
                this.$confirm('此操作将永久删除当前选中记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error'
                }).then(() => {
                    setTimeout(() => {
                        let count=0
                        for (var i = 0; i < this.selectRecordArray.length; i++) {
                            var selectRecordArray = this.selectRecordArray[i]
                            axios.get('/accessRecord/delete?id=' + selectRecordArray.id)
                                .then((res)=>{
                                   count+=1
                                    if (count>=this.selectRecordArray.length){
                                        this.refreshRecord()
                                    }
                                })
                                .catch((error) => {
                                    count+=1
                                    if (count>=this.selectRecordArray.length){
                                        this.refreshRecord()
                                    }
                                })
                        }
                        this.refreshRecord()
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            exportRecordInfo() {
                var startTime = new Date(this.datePickerValue[0]).getTime()
                var endTime = new Date(this.datePickerValue[1]).getTime()
                var parameter = {}
                var likeParameter = {
                    name: this.likeModel,
                    idCard: this.likeIdCardModel
                }
                if (this.recordValue === '成功') {
                    if (this.device === {}) {
                        parameter = {
                            success: 1
                        }
                    } else {
                        parameter = {
                            success: 1,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '失败') {
                    if (this.device === {}) {
                        parameter = {
                            success: 0,
                        }
                    } else {
                        parameter = {
                            success: 0,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '访客') {
                    if (this.device === {}) {
                        parameter = {
                            personType: 1,
                        }
                    } else {
                        parameter = {
                            personType: 1,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '黑名单') {
                    if (this.device === {}) {
                        parameter = {
                            personType: 3,
                        }
                    } else {
                        parameter = {
                            personType: 3,
                            accessDevice: this.device.id
                        }
                    }

                } else {
                    parameter = {
                        accessDevice: this.device.id
                    }
                }
                axios.post('/accessRecord/downloadRecordInfo', {
                    page: this.page,
                    length: this.length,
                    startTime: startTime,
                    endTime: endTime,
                    parameter: parameter,
                    likeParameter: likeParameter
                }, {
                    responseType: 'blob'
                }).then((res) => {
                    let data = res.data
                    const link = document.createElement('a')
                    let blob = new Blob([data], {
                        type: 'application/vnd.ms-excel'
                    })
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    link.download = '记录信息.xls'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                })
            },
            exportRecordPhoto() {
                var startTime = new Date(this.datePickerValue[0]).getTime()
                var endTime = new Date(this.datePickerValue[1]).getTime()
                var parameter = {}
                var likeParameter = {
                    name: this.likeModel,
                    idCard: this.likeIdCardModel
                }
                if (this.recordValue === '成功') {
                    if (this.device === {}) {
                        parameter = {
                            success: 1
                        }
                    } else {
                        parameter = {
                            success: 1,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '失败') {
                    if (this.device === {}) {
                        parameter = {
                            success: 0,
                        }
                    } else {
                        parameter = {
                            success: 0,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '访客') {
                    if (this.device === {}) {
                        parameter = {
                            personType: 1,
                        }
                    } else {
                        parameter = {
                            personType: 1,
                            accessDevice: this.device.id
                        }
                    }

                } else if (this.recordValue === '黑名单') {
                    if (this.device === {}) {
                        parameter = {
                            personType: 3,
                        }
                    } else {
                        parameter = {
                            personType: 3,
                            accessDevice: this.device.id
                        }
                    }

                } else {
                    parameter = {
                        accessDevice: this.device.id
                    }
                }
                axios.post('/accessRecord/downloadRecordPhoto', {
                    page: this.page,
                    length: this.length,
                    startTime: startTime,
                    endTime: endTime,
                    parameter: parameter,
                    likeParameter: likeParameter
                }, {
                    responseType: 'blob'
                }).then((res) => {
                    let data = res.data
                    const link = document.createElement('a')
                    let blob = new Blob([data], {
                        type: 'application/zip'
                    })
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    link.download = '记录图片.zip'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                })
            },
            getPersonType(type) {
                if (type === 0) {
                    return '常客'
                }
                if (type === 1) {
                    return '访客'
                }
                if (type === 2) {
                    return '管理员'
                }
                if (type === 3) {
                    return '黑名单'
                }
            },
        }
    }
</script>

<style>
    .door_record_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .door_record_tabler {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        margin: 20px;
        min-height: 100px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .door_record_data_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 100%;
        margin-bottom: 10px;
    }

    .el-table {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }

    .updateDevice-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .showUpdateDevice-card {
        width: 95%;
        height: 95%;
    }
</style>
